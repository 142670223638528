$body-bg: #121212;
$body-color: #fff;
$primary: #c63f26;

$input-bg: #000;
$input-disabled-bg: #343a40;
$input-color: $body-color;
$input-border-color: #6c757d;
$input-focus-color: $input-color;
$input-placeholder-color: #ced4da;
$input-plaintext-color: $body-color;
$input-group-addon-bg: #343a40;
$list-group-color: $body-color;

.gpt {
  padding-top: 66px !important;
}

.gpb {
  padding-bottom: 66px !important;
}

.gmt {
  margin-top: 66px !important;
}

.gmb {
  margin-bottom: 66px !important;
}

#masonry {
  margin-bottom: -24px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #343a40;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

html,
body {
  height: 100%;
}

.btn-close {
  filter: invert(1);
}

.navbar {
  pointer-events: none;
  z-index: 999;
}

.navbar > * {
  pointer-events: auto;
}

.navbar .navbar-brand {
  width: 50px;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .navbar-brand img {
  width: 50px;
  height: 50px;
}

.navbar .navbar-toggler {
  outline: none;
}

.container {
  max-width: 1140px !important;
}

.btn-primary,
.bg-primary,
.progress-bar {
  color: #343a40 !important;
}

.card,
.list-group-item {
  background-color: #343a40 !important;
  border-color: #808080 !important;
}

.list-group-flush {
  border-top: 1px solid #808080 !important;
}

#playercount,
#playercount-practice,
#playercount-retake {
  border-bottom: none !important;
}

.player {
  border-top: none !important;
  border-bottom: none !important;
}

.modal-content {
  background-color: #343a40 !important;
  border-color: #808080 !important;
}

.modal-header {
  border-bottom-color: #808080 !important;
}

.modal-footer {
  border-top-color: #808080 !important;
}

.close {
  color: #fff !important;
  text-shadow: 0 1px 0 #000 !important;
}

.mapboxgl-canvas {
  border: none !important;
  outline: none !important;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  padding: 12px !important;
}

.mapboxgl-popup-tip {
  margin: -1px;
}

.mapboxgl-ctrl-attrib a {
  color: #000 !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  min-height: 24px !important;
}

.list-group-item a:hover span.float-right {
  text-decoration: underline !important;
}

.page *:last-child {
  margin-bottom: 0;
}

.post .list-group {
  background: transparent;
}

.post .list-group-item {
  background: transparent;
  border-bottom: 1px solid #9a9a9a;
}

.post .list-group-item:last-of-type {
  border-bottom: none;
}

.post img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.post img[src*="Screen%20Shot"] {
  zoom: 50%;
}

.post ol img {
  margin-left: 0;
}

.post *:last-child {
  margin-bottom: 0;
}

a.disabled {
  text-decoration: line-through;
}

#timeline {
  width: 100%;
}

#timeline rect,
#timeline path {
  stroke-width: 0;
}

#timeline text {
  fill: #fff;
}

#timeline svg g:first-of-type rect:not(:last-child) {
  fill: #121212;
}

.grid {
  text-align: center;
  font-size: 0;
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
  vertical-align: middle;
  padding: 0;
  grid-gap: 12px;
}

.grid-about {
  grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
}

.grid-about a:any-link {
  color: #fff;
  font-size: 1rem;
  padding: 16px;
  text-decoration: none;
}

.grid-about a:any-link img {
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  pointer-events: none;
  filter: invert(1);
}

.grid-about a:any-link span {
  line-height: 100%;
  margin-top: 8px;
  display: block;
  white-space: nowrap;
}

.grid-about a.big:any-link {
  grid-column: span 2;
  grid-row: span 2;
  padding: 48px;
}

.grid-about a.big:any-link img {
  width: 64px;
  height: 64px;
}

.colors button {
  margin-bottom: 12px;
}

.reference img {
  width: 100%;
}

.conventions h5 {
  margin-top: 32px;
}

.repo-status-concept {
  background-color: #fff;
}

.repo-status-wip {
  background-color: #dfb317;
}

.repo-status-suspended {
  background-color: #fe7d37;
}

.repo-status-abandoned {
  background-color: #e05d44;
}

.repo-status-active {
  background-color: #4c1;
}

.repo-status-inactive {
  background-color: #a4a61d;
}

.repo-status-unsupported {
  background-color: #9f9f9f;
}

.repo-status-moved {
  background-color: #e05d44;
}

.badge a, .badge .a:link, .badge a:hover {
  color: inherit;
  text-decoration: inherit;
}

.emoji {
  height: 1em;
  position: relative;
  top: -4px;
  vertical-align: bottom;
}

.rainbow {
  --angle: 0deg;
  border: 2px solid;
  border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
  animation: 10s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.more {
  display: none;
}

@media only screen and (min-width: 576px) {
  .col-left {
    padding-right: 0 !important;
    border-right: none;
  }
  .col-right {
    padding-left: 0 !important;
    border-left: 1px solid #808080;
  }
}
